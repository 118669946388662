
import { defineComponent, computed, ref } from 'vue'
import MasterTable from '@/components/master/MasterTable.vue'
import {
  required as requiredRule,
  booleanTrueRequired,
} from '@/common/formValidationRules'
import { v4 as uuidv4 } from 'uuid'
import { useVehicleWithdrawalResponsible } from '@/composable/useVehicleWithdrawalResponsible'
import { VehicleWithdrawalResponsible } from '@/types/vehicle-withdrawal-responsible'

export default defineComponent({
  name: 'VehicleWithdrawalResponsibles',

  components: {
    MasterTable,
  },

  setup() {
    const {
      loading: loadingState,
      data: responsibles,
      add,
      update,
      remove,
      fetchAll,
    } = useVehicleWithdrawalResponsible()

    fetchAll()

    function createFormModel(): Partial<VehicleWithdrawalResponsible> {
      return {
        uuid: uuidv4(),
        name: '',
      }
    }
    const model = ref<Partial<VehicleWithdrawalResponsible>>(createFormModel())

    function resetFormModel() {
      model.value = createFormModel()
    }

    const columns = [
      {
        name: 'name',
        label: 'Ansvarig',
        align: 'left',
        field: 'name',
        sortable: true,
      },
    ]

    const loading = computed(() => {
      return {
        ...loadingState.value,
      }
    })

    const exportData = computed(() => {
      return responsibles.value.map((responsible) => {
        return {
          Depå: responsible.name,
        }
      })
    })

    return {
      columns,
      model,
      requiredRule,
      booleanTrueRequired,
      responsibles,
      add,
      remove,
      update,
      loading,
      resetFormModel,
      exportData,
    }
  },
})
