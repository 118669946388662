import { Ref, ref, ComputedRef, computed } from 'vue'
import { useLoading, Loading } from '@/composable/useLoading'
import { VehicleWithdrawalResponsible } from '@/types/vehicle-withdrawal-responsible'
import { getVehicleWithdrawalResponsibles } from '@/api/vehicle-withdrawal-responsible/getVehicleWithdrawalResponsibles'
import { deleteVehicleWithdrawalResponsible } from '@/api/vehicle-withdrawal-responsible/deleteVehicleWithdrawalResponsible'
import { createVehicleWithdrawalResponsible } from '@/api/vehicle-withdrawal-responsible/createVehicleWithdrawalResponsible'
import { updateVehicleWithdrawalResponsible } from '@/api/vehicle-withdrawal-responsible/updateVehicleWithdrawalResponsible'

interface UseVehicleWithdrawalResponsible {
  data: Ref<VehicleWithdrawalResponsible[]>
  fetchAll: () => Promise<VehicleWithdrawalResponsible[]>
  remove: (uuid: string) => Promise<null>
  add: (
    body: VehicleWithdrawalResponsible
  ) => Promise<VehicleWithdrawalResponsible>
  update: (
    body: VehicleWithdrawalResponsible
  ) => Promise<VehicleWithdrawalResponsible>
  loading: ComputedRef<Loading>
}

const data = ref<VehicleWithdrawalResponsible[]>([])
const { state } = useLoading()

export function useVehicleWithdrawalResponsible(
  initFetchAll?: boolean
): UseVehicleWithdrawalResponsible {
  async function fetchAll() {
    state.getAll = true
    return new Promise<VehicleWithdrawalResponsible[]>((resolve, reject) => {
      getVehicleWithdrawalResponsibles()
        .then(({ data: VehicleWithdrawalResponsibles }) => {
          data.value = VehicleWithdrawalResponsibles
          resolve(VehicleWithdrawalResponsibles)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  async function remove(uuid: string) {
    state.delete = true
    return new Promise<null>((resolve, reject) => {
      deleteVehicleWithdrawalResponsible(uuid)
        .then(() => {
          data.value = data.value.filter((item) => item.uuid !== uuid)
          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          state.delete = false
        })
    })
  }

  async function add(body: VehicleWithdrawalResponsible) {
    state.create = true
    return new Promise<VehicleWithdrawalResponsible>((resolve, reject) => {
      createVehicleWithdrawalResponsible(body)
        .then(({ data: VehicleWithdrawalResponsible }) => {
          data.value.push(VehicleWithdrawalResponsible)
          resolve(VehicleWithdrawalResponsible)
        })
        .catch(reject)
        .finally(() => {
          state.create = false
        })
    })
  }

  async function update(body: VehicleWithdrawalResponsible) {
    state.update = true
    return new Promise<VehicleWithdrawalResponsible>((resolve, reject) => {
      updateVehicleWithdrawalResponsible(body)
        .then(({ data: VehicleWithdrawalResponsible }) => {
          data.value = data.value.map((item) => {
            if (item.uuid === VehicleWithdrawalResponsible.uuid) {
              return {
                ...item,
                ...VehicleWithdrawalResponsible,
              }
            }

            return item
          })
          resolve(VehicleWithdrawalResponsible)
        })
        .catch(reject)
        .finally(() => {
          state.update = false
        })
    })
  }

  if (initFetchAll) {
    fetchAll()
  }

  return {
    data,
    fetchAll,
    add,
    remove,
    update,
    loading: computed(() => state),
  }
}
